var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "exporter" },
    [
      _c("MobileMenu"),
      _c(
        "div",
        { staticClass: "df jc-sb ai-c" },
        [
          _c(
            "div",
            { staticClass: "p-t-10 p-b-10" },
            [
              _c("Icon", {
                attrs: {
                  name: "LogoPortTranzit",
                  width: _vm.isMobile ? "200px" : "285px",
                  height: "auto",
                },
              }),
            ],
            1
          ),
          !_vm.isMobile
            ? _c(
                "el-menu",
                {
                  staticClass: "exporter__navigation",
                  attrs: { mode: "horizontal" },
                },
                [
                  _vm._l(_vm.options, function (item) {
                    return _c(
                      "el-menu-item",
                      {
                        key: item.id,
                        class: { "is-active": item.id === _vm.activePageId },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.changePage(item)
                          },
                        },
                      },
                      [
                        _c("Icon", { attrs: { name: item.icon } }),
                        _vm._v(" " + _vm._s(item.title) + " "),
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-menu-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("logout")
                        },
                      },
                    },
                    [
                      _c("Icon", {
                        attrs: {
                          name: "IconGoToTerminal",
                          width: "18px",
                          height: "18px",
                        },
                      }),
                      _vm._v(" Выход "),
                    ],
                    1
                  ),
                ],
                2
              )
            : _c(
                "div",
                { staticClass: "exporter__mobileButton" },
                [
                  _c("Icon", {
                    attrs: {
                      name: "IconMenuLine",
                      width: "44",
                      height: "auto",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleOpenMobileMenu.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }